<script>
import { $ } from '@clevver/selector'

const prevent = (e) => { e.preventDefault(); e.stopPropagation() }

export default {
  props: {
    accept: {
      type: Array,
      required: false
    }
  },

  data() {
    return {
      dragging: false,
    }
  },

  methods: {
    browseFiles(e) {
      $('input[type="file"]', this.$el).click()
    },

    dragEnter(e) {
      prevent(e)
      let $dz = this.$refs.dz

      if (this.dragging) { return }

      this.dragging = true

      this.$emit("dzEnter")

      $dz.classList.add('drop-zone--dragging')
    },

    fileSelected(e) {
      console.log("This is when its done manually")
      this.$emit("dzDrop", {
        fileList: e.target.files,
        source: this,
      })
    },

    dragLeave(e) {
      prevent(e)

      let $dz = this.$refs.dz

      this.dragging = false

      this.$emit("dzLeave")
      $dz.classList.remove('drop-zone--dragging')
    },

    drop(e) {
      prevent(e)

      let $dz = this.$refs.dz
      $dz.classList.remove('drop-zone--dragging')

      this.dragging = false

      let dt = e.dataTransfer
      let files = dt.files


      // We only allow uploading one file at a time currently...
      // TODO: Emit an error so we can handle it in the UI and notifiy the user of the problem
      // if (files.length > 1) { return }

      // If the 'accept' prop is set, compare the file's mimetype against the
      // mimetypes in the array. If it's not in them, reject it.
      if (this.accept) {
        let accepted = false

        for (let i = 0; i < this.accept.length; i++) {
          if (files[0].type == this.accept[i]) {
            accepted = true
            break
          }
        }

        if (!accepted) { return }
      }

      // Only allowing one file for now, find the first one we support. Other file
      // types coming later, such as pdf and mp3.
      // let types = ['image/gif', "image/jpeg", "image/png", "image/svg", "image/svg+xml"]

      // // TODO: We need to do better than this.  Maybe allow all files
      // // unless the drop zone is specifically for a certain type of file.
      // types.push("application/pdf")

      // types.push("audio/mp4")
      // types.push("audio/m4a")
      // types.push("audio/x-m4a")
      // types.push("audio/ogg")
      // types.push("audio/mp3")

      console.log(files[0].type)

      // // TODO: Emit an error so we can handle it in the UI and notifiy the user of the problem
      // if (!types.includes(files[0].type)) { return }

      this.$emit("dzDrop", {
        fileList: files,
        source: this,
      })
    },
  },

  mounted() {
    let $dz = this.$refs.dz

    $dz.addEventListener('dragenter', this.dragEnter, false)
    $dz.addEventListener('dragover', this.dragEnter, false)

    $dz.addEventListener('dragleave', this.dragLeave, false)
    $dz.addEventListener('drop', this.drop, false)
  }
}
</script>

<template>
  <div class="drop-zone" ref="dz" @click="browseFiles">
    <slot></slot>
    <input type="file" style="position:fixed; top: -1000px;" name="file" @change="fileSelected" />
  </div>
</template>

<style lang="scss" scoped>
  .drop-zone {
    cursor: pointer;
  }
</style>